import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../scss/global.scss";
import "../scss/darkmode.scss";
import "../scss/front-page.scss";

import { PostChip } from "../components/PostChip";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="post-chip-container">
        {posts
          .filter(
            ({ node }) => !!node.frontmatter && !!node.frontmatter.featuredImage
          )
          .map(({ node }) => (
            <PostChip key={node.fields.slug} post={node} />
          ))}
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query($liveDate: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { date: { lte: $liveDate } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            author
            featuredImage {
              childImageSharp {
                fixed(width: 1080) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
