import React from "react";
import { DateTime } from "luxon";

export const PostMetadata = ({ post, showDate }) => {
  const { author } = post.frontmatter;

  const dateParsed = DateTime.fromISO(post.frontmatter.date);
  const dateFormatted = dateParsed.toFormat("EEEE, LLLL d");

  return (
    <p className="postDate">
      <b>{author}</b>
      {showDate && ` on ${dateFormatted}`}
    </p>
  );
};
