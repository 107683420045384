import React from "react";
import { Link } from "gatsby";
import { PostMetadata } from "./PostMetadata";

export const PostChip = ({ post, showDate }) => (
  <Link to={post.fields.slug}>
    <div
      className="post-link"
      style={{
        backgroundImage: `url('${post.frontmatter.featuredImage.childImageSharp.fixed.src}')`,
      }}
    >
      <div className="backdrop" />
      <h3>{post.frontmatter.title}</h3>
      <p>
        <PostMetadata post={post} showDate={showDate || false} />
        <span>{post.frontmatter.description}</span>
      </p>
    </div>
  </Link>
);
